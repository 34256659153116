import React, { useEffect, useRef, useState } from 'react'
import { AddBanners, deleteBanners, ImageUpload, ReadBanner } from '../Firebase';
import { Banner } from '../types';

const BannerPage = () => {
  const [bannerList, setBannerList] = useState(Array<Banner>());
  const [imag, setimage] = useState<Blob | MediaSource>();
  const [banner, setBanner] = useState('');
  const [error, setError] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [bannerLebal, setBannerLebal] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  
  const handleClick = () => {
    inputRef.current?.click()
  };

  const onFilechange = ( e:any ) => {
    setPercentage(0);
    setimage(e.target.files[0]);
    ImageUpload(e.target.files[0],(url) => {
      setBanner(url);
    }, (percentage) => {
      setPercentage(percentage);
    })
  }

  const submitBanner = () => {
    AddBanners(banner, bannerLebal, () => {
      getBanner();
    })
    
  }

  const deleteBanner = (item: Banner) => {
    deleteBanners(item.Id, () => {
      getBanner();
    })
  }

  const getBanner = () => {
    ReadBanner((data) => {
      setBannerList(data)
    })
  }

  useEffect(() => {
    getBanner();
  }, [])

  return (
    <div className="container mt-5">
    <div className="row tm-content-row">
      <div className="col-12 tm-block-col">
        <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
          <h2 className="tm-block-title">List of Banners</h2>
          <table className="table table-hover tm-table-small tm-product-table">
                <thead>
                  <tr>
                    <th scope="col" >&nbsp;</th>
                    <th scope="col">BANNER</th>
                    <th scope="col">DETAIL</th>
                    <th scope="col">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    bannerList.map((item, index) => 
                      
                      <tr key={index}>
                        <th scope="row"><input type="checkbox" onClick={() => {
                          
                        }}/></th>
                        <td><img height={100} width={150} src={item.banner}/></td>
                        <td className="tm-product-name">{item.label}</td>
                        <td onClick={() => {
                          deleteBanner(item);
                      }}>
                        Delete
                      </td>
                      </tr>
                    )
                  }
                      
                </tbody>
              </table>
        </div>
        
        <div className="row tm-bg-primary-dark tm-edit-product-row m-0 p-4">
          <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="form-group mb-3">
                <label
                  htmlFor="name"
                  >Product Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={bannerLebal}
                  onChange={e => setBannerLebal(e.target.value)}
                  className="form-control validate"
                  required
                />
              </div>
              <div className="tm-product-img-dummy mx-auto" onClick={handleClick}>
              <i
                className="fas fa-cloud-upload-alt tm-upload-icon"
                style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
                // onClick="document.getElementById('fileInput').click();"
              ></i>
              <label className='text-dark mx-auto mb-1' style={{position:'absolute'}}>{percentage} %</label>
            </div>
            <div className="custom-file mt-3 mb-3">
              {error === 'Error' ? (
                <label className='text-danger'>Please Upload Image</label>
              ) : null}
              <input id="fileInput" type="file" style={{display: 'none'}} ref={inputRef} onChange={onFilechange} />
              <input
                type="button"
                className="btn btn-primary btn-block mx-auto"
                value="UPLOAD Banner"
                onClick={() => submitBanner()}
                // onClick="document.getElementById('fileInput').click();"
              />
            </div>
            <div className='live__scroll flex-nowrap'>
                
                  <img className='d-inline-block m-1' height={'15%'} width={'15%'} src={imag ? URL.createObjectURL(imag) : ''} />
                
              
                
            </div>
          </div>
          
        </div>
          
      </div>

    </div>
  </div>
  )
}

export default BannerPage