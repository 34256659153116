import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Form } from "react-router-dom"
import { deletePromoCode, ReadPromoCode, WritePromoCode } from '../Firebase'
import { setLoader } from '../Redux/Authentication/Login';
import { PromoCode } from '../types';

const AddPromoCode = () => {
  const [promoCodeList, setPromoCodeList] = useState(Array<PromoCode>());
  const [promoCode, setPromoCode] = useState(({
    title: "",
    code: "",
    percentage: 0,
  }))
  const dispatch = useDispatch()

  const handleChange = (e: any) => {
    setPromoCode({
      ...promoCode,
      [e.target.name]: e.target.value
    })
  }
  
  const updateData = () => {
    ReadPromoCode((data) => {
      console.log(data);
      
      setPromoCodeList(data)
      dispatch(setLoader(false));
    })
  }

  
 React.useEffect(() => {
  dispatch(setLoader(true));
  updateData()
 }, [])

  return (
    <div className="container tm-mt-big tm-mb-big">
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-8 mx-auto">
          <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
            <div className="row">
              <div className="col-12">
                <h2 className="tm-block-title d-inline-block">Add PromoCode</h2>
              </div>
            </div>
            <Form method='post' className="tm-edit-product-form" onSubmit={(e) => { 
                e.preventDefault()
                WritePromoCode(promoCode.code, promoCode.percentage, promoCode.title).then((data) => {
                  updateData()
                })
            }}>
            <div className="tm-edit-product-row">
              <div className="row col-xl-12 col-lg-12 col-md-12">
                
                  <div className="form-group mb-3 col-xl-4 col-lg-4 col-md-4">
                    <input
                      id="title"
                      name="title"
                      type="text"
                      placeholder='Title'
                      value={promoCode.title}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-lg-4 col-md-4">
                    <input
                      id="code"
                      name="code"
                      type="text"
                      placeholder='Promo Code'
                      value={promoCode.code}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>

                  <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-3">
                    <input
                      id="percentage"
                      name="percentage"
                      type="text"
                      placeholder='Percentage'
                      value={promoCode.percentage}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>
              
                  
              </div>
       
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block text-uppercase">Add PromoCode</button>
              </div>
            </div>
            </Form>
          </div>
          
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 tm-block-col">
          <div className="tm-bg-primary-dark tm-block tm-block-product-categories">
            <h2 className="tm-block-title">Promo Codes</h2>
            <div className="tm-product-table-container">
              <table className="table tm-table-small tm-product-table">
                <tbody>
                  {
                    promoCodeList.map((data, index) => (
                      <tr key={index}>
                        <td className="tm-product-name">
                          <div>Title:- {data.Title}</div>
                          <div>Code:- {data.Code}</div>
                          <div>Percentage:- {data.Percentage}</div>
                        </td>
                        <td className="text-center" onClick={() => {
                          dispatch(setLoader(true));
                          deletePromoCode(data.Code, () => {
                            updateData();
                          })
                        }}>
                          <a href="#" className="tm-product-delete-link">
                            <i className="far fa-trash-alt tm-product-delete-icon"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div> 
    </div>
  )
}

export default AddPromoCode