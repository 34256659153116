import React, { useState } from 'react'
import { activeOrDeactiveUser, ReadBillingData } from '../Firebase';
import { PaymentDetail } from '../types';

const Billing = () => {
  const [paymentDetailList, setPaymentDetailList] = useState(Array<PaymentDetail>());

  React.useEffect(() => {
    ReadBillingData((data) => {
      setPaymentDetailList(data);
    });
  }, []);

  
  return (
    <div className="m-5">
    <div className="row tm-content-row">
      <div className="col-12 tm-block-col">
        <div className="tm-bg-primary-dark tm-block-h-auto p-5">
          <h2 className="tm-block-title">List of Accounts</h2>
          <table className="table table-hover tm-table-small tm-product-table">
                <thead>
                  <tr>
                    <th scope="col">Bill No.</th>
                    <th scope="col">Customer ID</th>
                    <th scope="col">Product ID</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Stripe ID</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                {
                    paymentDetailList.map((data, index) => (
                      <tr>
                        <td>{index}</td>
                        <td>{data.customer_id}</td>
                        <td className="tm-product-name">{data.product_id}</td>
                        <td>{data.currency}</td>
                        <td>{data.quantity}</td>
                        <td>{data.p_stripe_id}</td>
                        <td>{data.payment_status}</td>
                      </tr>
                  ))
                }

                {
                  paymentDetailList.length === 0 ? (
                    <span className="text-warning">Accounts Not Found</span>
                  ) : (null)
                }
                </tbody>
              </table>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Billing