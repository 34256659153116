import React, { useRef, useState } from 'react'
import { Form, useNavigate } from "react-router-dom"
import { ReadCategory, UploadProduct, WriteImage } from '../Firebase';
import { Category, Product } from '../types';

const AddProduct = () => {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState(Array<Category>());
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [product, setProduct] = useState(({
    name: "",
    description: "",
    category: "",
    festival: "",
    expire_date: "",
    original_price: "",
    current_price: "",
    stock: "",
    images: "",
    tax: "0%",
  }))
  const [imag, setimage] = useState(Array<any>);
  const [error, setError] = useState('');
  const [percentage, setPercentage] = useState(0);

  const handleClick = () => {
    inputRef.current?.click()
  };

  const onFilechange = ( e:any ) => {
    setPercentage(0);
    setimage([...imag, e.target.files[0]]);
  }

  const handleChange = (e: any) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  const updateData = () => {
    ReadCategory((data) => {
      setCategoryList(data)
    })
  }

  
 React.useEffect(() => {
  updateData()
 }, [])

  return (
    <div className="container tm-mt-big tm-mb-big">
      <div className="row">
        <div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
          <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
            <div className="row">
              <div className="col-12">
                <h2 className="tm-block-title d-inline-block">Add Product</h2>
              </div>
            </div>
            <Form method='post' ref={formRef} className="tm-edit-product-form" onSubmit={(e) => { 
                e.preventDefault()
                if(imag.length > 0){
                  WriteImage(imag, (url) => {
                    UploadProduct(product, url).then(() => {
                      navigate(-1);
                    });
                  }, (percentage) => {
                    setPercentage(percentage);
                  })
                  
                } else {
                  setError('Error')
                }
                
                
            }} onError={(e) => {
              console.log(e);
              
            }}>
            <div className="row tm-edit-product-row">
              <div className="col-xl-6 col-lg-6 col-md-12">
                
                  <div className="form-group mb-3">
                    <label
                      htmlFor="name"
                      >Product Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={product.name}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="description"
                      >Description</label
                    >
                    <textarea
                      className="form-control validate"
                      name="description"
                      value={product.description}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>

                  <div className="form-group mb-3">
                    <label
                      htmlFor="category"
                      >Category</label
                    >
                    <select
                      className="custom-select tm-select-accounts"
                      name="category"
                      onChange={handleChange}
                      id="category"
                    >
                      <option selected>Select category</option>
                      {
                        categoryList.map((data, index) => (
                          <option key={index} value={data.Id}>{data.Name}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="festival">Festival</label>
                    <select
                      className="custom-select tm-select-accounts"
                      name="festival"
                      onChange={handleChange}
                      id="festival"
                    >
                      <option selected>Select Festival</option>
                        <option>Festival 1</option>
                        <option>Festival 2</option>
                        <option>Festival 3</option>
                        <option>Festival 4</option>
                        <option>Festival 5</option>
                        <option>Festival 6</option>
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="name"
                      >Original Price
                    </label>
                    <input
                      id="originalprice"
                      name="original_price"
                      type="text"
                      value={product.original_price}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="name"
                      >Current Price
                    </label>
                    <input
                      id="currentprice"
                      name="current_price"
                      type="text"
                      value={product.current_price}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>
                  <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label
                            htmlFor="expire_date"
                            >Expire Date
                          </label>
                          <input
                            id="expire_date"
                            name="expire_date"
                            type="date"
                            value={product.expire_date}
                            onChange={handleChange}
                            className="form-control validate"
                            data-large-mode="true"
                          />
                        </div>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label
                            htmlFor="stock"
                            >Units In Stock
                          </label>
                          <input
                            id="stock"
                            name="stock"
                            type="text"
                            value={product.stock}
                            onChange={handleChange}
                            className="form-control validate"
                            required
                          />
                        </div>
                  </div>
                  
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 mx-auto mb-4">
                <div className="tm-product-img-dummy mx-auto">
                  <i
                    className="fas fa-cloud-upload-alt tm-upload-icon"
                    style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
                    // onClick="document.getElementById('fileInput').click();"
                  ></i>
                  <label className='text-dark mx-auto mb-1' style={{position:'absolute'}}>{percentage} %</label>
                </div>
                <div className="custom-file mt-3 mb-3">
                  {error === 'Error' ? (
                    <label className='text-danger'>Please Upload Image</label>
                  ) : null}
                  <input id="fileInput" type="file" style={{display: 'none'}} ref={inputRef} onChange={onFilechange}/>
                  <input
                    type="button"
                    className="btn btn-primary btn-block mx-auto"
                    value="UPLOAD PRODUCT IMAGE"
                    onClick={handleClick}
                    // onClick="document.getElementById('fileInput').click();"
                  />
                </div>
                <div className='live__scroll flex-nowrap'>
                  {
                    imag.map((file) => (
                      <img className='d-inline-block m-1' height={'15%'} width={'15%'} src={URL.createObjectURL(file)} />
                    ))
                  }
                    
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block text-uppercase">Add Product Now</button>
              </div>
            </div>
            </Form>
          </div>

      </div>
    </div> 
    </div>
  )
}

export default AddProduct