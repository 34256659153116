import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form, useNavigate } from "react-router-dom";
import { DeleteProductReview, ImageUpload, ProductDetail, ReadCategory, ReadProductReview, RemoveImage, UpdateProduct, WriteImage } from '../Firebase';
import { setLoader } from '../Redux/Authentication/Login';
import { RootState } from '../Redux/Store';
import { Category, Product, Review } from '../types';

function EditProduct() {
  const [categoryList, setCategoryList] = useState(Array<Category>());
  const [reviewList, setReviewList] = useState(Array<Review>());
  let selectProduct: string | null = useSelector((state: RootState) => state.login.selectProduct)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null);
  const [percentage, setPercentage] = useState(0);
  const [product, setProduct] = useState({
    Name: "",
    Description: "",
    Category_id: "",
    Festival: "",
    Expire_date: "",
    Original_price: "",
    Images: Array<string>(),
    Current_price: "",
    Stock: "",
    Tax: "0%",
    Active: true,
  } as unknown as Product)

  React.useEffect(() => {
    if(!selectProduct) return;
    ProductDetail(selectProduct, (data) => {
      setProduct(data);
    })
  }, [selectProduct]);

  const handleClick = () => {
    inputRef.current?.click()
  };

  window.addEventListener('load', () => {
    if(!selectProduct) return;
    ProductDetail(selectProduct, (data) => {
      setProduct(data);
    })
    
    
  });

  const ImageArrayHandle = (url: string) => {
    if (product.Images !== undefined){
      const urls = product.Images.filter(e => e !== 'load');
      product.Images = [...urls, url]
    }else {
      product.Images = Array<string>(url);
    }
  }

  const onFilechange = ( e:any ) => {
    ImageArrayHandle('load')
    setPercentage(0);
    ImageUpload(e.target.files[0],(url) => {
      ImageArrayHandle(url)
      setProduct(JSON.parse(JSON.stringify(product)));
    }, (percentage) => {
      setPercentage(percentage);
    })
    
  }

  const handleChange = (e: any) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  const updateData = () => {
    ReadCategory((data) => {
      setCategoryList(data)
    })
    if(selectProduct){
      ReadProductReview(selectProduct, data => {
        setReviewList(data);
        dispatch(setLoader(false));
      })
    }
  }

  React.useEffect(() => {
    dispatch(setLoader(true));
    updateData()
   }, [])

  const InsertAndDeleteImage = (url: string) => {
    const newImageArray = product.Images.filter(e => e !== url);
    RemoveImage(newImageArray, product.Id, () => {
      product.Images = newImageArray;
      setProduct(JSON.parse(JSON.stringify(product)));
    })
  }

  return (
    <div className="container tm-mt-big tm-mb-big">
      
    <div className="row">
      <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 mx-auto">
        <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
          <div className="row">
            <div className="col-12">
              <h2 className="tm-block-title d-inline-block">Edit Product</h2>
            </div>
          </div>
          <Form action="destroy" method="post" className="tm-edit-product-form" onSubmit={(e) => {
             e.preventDefault() 
                UpdateProduct(product, product.Id,() => {
                  navigate(-1);
                });
          }}>
          <div className="row tm-edit-product-row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              
                <div className="form-group mb-3">
                  <label
                    htmlFor="name"
                    >Product Name
                  </label>
                  <input
                    id="name"
                    name="Name"
                    type="text"
                    onChange={handleChange}
                    value={product?.Name}
                    className="form-control validate"
                  />
                </div>
                <div className="form-group mb-3">
                  <label
                    htmlFor="description"
                    >Description</label>
                  <textarea                    
                    className="form-control validate tm-small"
                    required
                    name="Description"
                    value={product?.Description}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label
                    htmlFor="category"
                    >Category</label>
                  <select
                    className="custom-select tm-select-accounts"
                    name="Category_id"
                    onChange={handleChange}
                    value={product.Category_id} 
                    id="category"
                  >
                    <option value="DEFAULT">Select category</option>
                    {
                        categoryList?.map((data, index) => (
                          <option key={index} value={data.Id}>{data.Name}</option>
                        ))
                      }
                  </select>
                </div>
                <div className="form-group mb-3">
                    <label
                      htmlFor="festival">Festival</label>
                    <select
                      className="custom-select tm-select-accounts"
                      name="Festival"
                      onChange={handleChange}
                      value={product.Festival} 
                      id="Festival"
                    >
                      <option selected>Select Festival</option>
                        <option>Festival 1</option>
                        <option>Festival 2</option>
                        <option>Festival 3</option>
                        <option>Festival 4</option>
                        <option>Festival 5</option>
                        <option>Festival 6</option>
                    </select>
                </div>
                <div className="form-group mb-3">
                    <label
                      htmlFor="name"
                      >Original Price
                    </label>
                    <input
                      id="originalprice"
                      name="Original_price"
                      type="text"
                      value={product.Original_price}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>
                <div className="form-group mb-3">
                    <label
                      htmlFor="name"
                      >current Price
                    </label>
                    <input
                      id="price"
                      name="Current_price"
                      type="text"
                      value={product?.Current_price}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>
                <div className="row">
                    <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label
                          htmlFor="expire_date"
                          >Expire Date
                        </label>
                        <input
                          id="expire_date"
                          name="Expire_date"
                          type="date"
                          onChange={handleChange}
                          value={product?.Expire_date}
                          className="form-control validate"
                          data-large-mode="true"
                        />
                      </div>
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label
                          htmlFor="stock"
                          >Units In Stock
                        </label>
                        <input
                          id="stock"
                          name="Stock"
                          type="text"
                          value={product?.Stock}
                          onChange={handleChange}
                          className="form-control validate"
                        />
                      </div>
                </div>
                
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 mx-auto mb-4">
              <div className='row mx-auto col-lg-12 col-md-12'>
                {
                  
                  product?.Images?.map((url: string, index) => (
                    <div key={index} className='col-lg-3 col-md-3 d-flex pb-1 justify-content-center align-items-center tm-product-img-edit'>
                      
                      <i
                    className="fa fa-times tm-remove-icon text-white"
                    style={{backgroundColor: '#000000b0', zIndex: 10}}
                    onClick={() => InsertAndDeleteImage(url)}
                  ></i>
                  
                      {url === 'load' ? (
                        <div className="loader-image"> </div>
                      ) : (
                        <img src={url} style={{objectFit:'cover'}} alt="Product image" className="d-block" height={80} width={50}/>   
                      )}
                      </div>
                  ))
                }
                
                </div>
              <div className="custom-file mt-3 mb-3">
                <input id="fileInput" type="file" style={{display: 'none'}} ref={inputRef} onChange={onFilechange}/>
                <input
                  type="button"
                  className="btn btn-primary btn-block mx-auto"
                  value="UPLOAD NEW IMAGE"
                  onClick={handleClick}
                />
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary btn-block text-uppercase">Update Now</button>
            </div>
          
          </div>
          </Form>
        </div>
       
      </div>
      
    </div>
    
    <div className="row tm-content-row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 tm-block-col">
        <div className="row">
            <div className="col-12">
              <h2 className="tm-block-title d-inline-block mt-4">Product Review</h2>
            </div>
          </div>
          <div className="tm-bg-primary-dark tm-block tm-block-products">
            
            <div className="tm-product-table-container">
              <table className="table table-hover tm-table-small tm-product-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Date</th>
                    <th scope="col">Title</th>
                    <th scope="col">Review</th>
                    <th scope="col">&nbsp;</th>
                    
                  </tr>
                </thead>
                <tbody>
                    {
                      reviewList.map(item => 
                        <tr>
                        <td>{item.name}</td>
                        <td className="tm-product-name">{item.email}</td>
                        <td>{item.date}</td>
                        <td>{item.review}</td>
                        <td>{item.body_review}</td>
                        <td onClick={() => {
                          if(selectProduct){
                            DeleteProductReview(selectProduct, item.id, () => {
                              if(selectProduct){
                                ReadProductReview(selectProduct, data => {
                                  setReviewList(data);
                                  dispatch(setLoader(false));
                                })
                             }
                            })
                          }
                          
                        }}>
                          <a className="tm-product-delete-link">
                            <i className="far fa-trash-alt tm-product-delete-icon"></i>
                          </a>
                        </td>
                      </tr>
                      )
                    }
                </tbody>
              </table>
            </div>
           
          </div>
        </div>
      </div>
  </div>
  
  )
}

export default EditProduct