import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ProductStock } from '../../Firebase';
ChartJS.register(ArcElement, Tooltip, Legend);


export const DoughnutCharts = () => {
  const [stock, setStock] = useState<Array<Object>>();
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top' as const,
            labels: {
                font: {
                    size: 14
                },
                color: '#fff'
            }, 
          },
          
        },
      };

    useEffect(() => {
      ProductStock(data => {
        setStock(data);
      });
    }, [])

      
    const data = {
        labels: stock?.map(item => {
          return JSON.parse(JSON.stringify(item))['name']
        }),
        datasets: [
          {
            label: '# of Votes',
            data: stock?.map(item => {
              return JSON.parse(JSON.stringify(item))['count']
            }),
            backgroundColor: stock?.map(item => {
              return JSON.parse(JSON.stringify(item))['color']
            }),
            borderColor: [
              'rgba(255, 255, 255, 1)'
            ],
            borderWidth: 1,
            weight: 1,
          },
        ],
      };
      
  return (
    <div style={{height: '350px'}}>
        <Doughnut data={data} options={options}/> 
    </div>
  )
}

