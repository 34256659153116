import React, { useState } from 'react'
import { Form, useNavigate } from "react-router-dom";
import { activeOrDeactiveUser, userAccounts } from '../Firebase';
import { UserAccount } from '../types';

const Account = () => {
  const [accountList, setAccountList] = useState(Array<UserAccount>());

  React.useEffect(() => {
    userAccounts((data) => {
      setAccountList(data);
    });
  }, []);

  console.log(`${accountList.length}`)
  return (
    <div className="container mt-5">
    <div className="row tm-content-row">
      <div className="col-12 tm-block-col">
        <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
          <h2 className="tm-block-title">List of Accounts</h2>
          <table className="table table-hover tm-table-small tm-product-table">
                <thead>
                  <tr>
                    <th scope="col" >&nbsp;</th>
                    {/* <th scope="col">PROFILE</th> */}
                    <th scope="col">NAME</th>
                    <th scope="col">LAST</th>
                    <th scope="col">MOBILE NO</th>
                    <th scope="col">Date</th>
                    <th scope="col">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                {
                    accountList.map((data, index) => (
                      <tr>
                        <th scope="row"><input type="checkbox" onClick={() => {
                          
                        }}/></th>
                        {/* <td><img style={{borderRadius: '100%'}} height={40} width={40} src={data.Profile}/></td> */}
                        <td className="tm-product-name">{data.Name}</td>
                        <td>{data.Last}</td>
                        <td>{data.Mobileno}</td>
                        <td>{data.Date}</td>
                        <td onClick={() => {
                        
                      }}>
                          
                          <label className="switch">
                            <input type="checkbox" checked={data.Active} onChange={(e) => {
                              console.log(e.target.checked);
                              activeOrDeactiveUser(data.Uid, e.target.checked, () => {})
                            }}/>
                            <span className="slider round"></span>
                          </label>
                          
                        </td>
                      </tr>
                  ))
                }

                {
                  accountList.length === 0 ? (
                    <span className="text-warning">Accounts Not Found</span>
                  ) : (null)
                }
                </tbody>
              </table>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Account