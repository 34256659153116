import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { getProductCount, ProductStock, UserCount } from '../Firebase'
import { DoughnutCharts } from './Charts/DoughnutCharts'
import { LinesChart } from './Charts/spline'
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { setLoader } from '../Redux/Authentication/Login';


const Dashboard = () => {
  const [product, ProductCount] = useState(0);
  const [cost, setCost] = useState(0);
  const [user, setUser] = useState(0);
  const dispatch = useDispatch()

  React.useEffect(() => {
    
    dispatch(setLoader(true));
    getProductCount((count, cost) => {
        ProductCount(count);
        setCost(cost);
        dispatch(setLoader(false));
    });
    UserCount((count) => setUser(count))
    
  }, [])


  return (
    <div className="container">
        <div className="row">
            <div className="col">
                
                <p className="text-white mt-5 mb-5">Welcome back, <b>Admin</b></p>
            </div>
        </div>
    
        <div className="row tm-content-row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 tm-block-col">
                <div className="tm-bg-primary-dark tm-block">
                    <h2 className="tm-block-title">Latest Hits</h2>
                    <LinesChart/>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 tm-block-col">
                <div className="tm-bg-primary-dark tm-block">
                    <h2 className="tm-block-title">Product Stock</h2>
                    <DoughnutCharts/>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 tm-block-col">
                <div className="tm-bg-primary-dark tm-block tm-block-taller">
                    <h2 className="tm-block-title">Management</h2>
                    <div id="pieChartContainer" className='nav-item ml-auto mr-0 row tm-content-row'>
                        <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                            <h5 className='text-white mt-5'>Product</h5>
                            <h2 className='text-white'><CountUp end={product} /></h2>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                            <h5 className='text-white mt-5'>Sales</h5>
                            <h2 className='text-white'>345</h2>
                        </div>
                        
                    </div>                        
                    <div id="pieChartContainer" className='nav-item ml-auto mr-0 row tm-content-row'>
                        {/* <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                            <h5 className='text-white mt-5'>Cost</h5>
                            <h2 className='text-white'>${cost}</h2>
                        </div> */}
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            <h5 className='text-white mt-5'>Today`s User</h5>
                            <h2 className='text-white'><CountUp end={user} /></h2>
                        </div>
                    </div>                        
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 tm-block-col">
                <div className="tm-bg-primary-dark tm-block tm-block-taller tm-block-overflow">
                    <h2 className="tm-block-title">Notification List</h2>
                    <div className="tm-notification-items">
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-01.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Jessica</b> and <b>6 others</b> sent you new <a href="#"
                                        className="tm-notification-link">product updates</a>. Check new orders.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-02.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Oliver Too</b> and <b>6 others</b> sent you existing <a href="#"
                                        className="tm-notification-link">product updates</a>. Read more reports.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-03.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Victoria</b> and <b>6 others</b> sent you <a href="#"
                                        className="tm-notification-link">order updates</a>. Read order information.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-01.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Laura Cute</b> and <b>6 others</b> sent you <a href="#"
                                        className="tm-notification-link">product records</a>.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-02.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Samantha</b> and <b>6 others</b> sent you <a href="#"
                                        className="tm-notification-link">order stuffs</a>.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-03.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Sophie</b> and <b>6 others</b> sent you <a href="#"
                                        className="tm-notification-link">product updates</a>.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-01.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Lily A</b> and <b>6 others</b> sent you <a href="#"
                                        className="tm-notification-link">product updates</a>.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-02.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Amara</b> and <b>6 others</b> sent you <a href="#"
                                        className="tm-notification-link">product updates</a>.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                        <div className="media tm-notification-item">
                            <div className="tm-gray-circle"><img src="img/notification-03.jpg" alt="Avatar Image" className="rounded-circle"/></div>
                            <div className="media-body">
                                <p className="mb-2"><b>Cinthela</b> and <b>6 others</b> sent you <a href="#"
                                        className="tm-notification-link">product updates</a>.</p>
                                <span className="tm-small tm-text-color-secondary">6h ago.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard