import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/Store';

const Loader = () => {
  const isLoader = useSelector((state: RootState) => state.login.loader);
  return isLoader ? (
    
        <div className='loader-container-m'>
          <div className="loader-container"> </div>
        </div>
  ) : null
}

export default Loader