import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { activeOrDeactiveProduct, deleteCategory, deleteProduct, ReadCategory, ReadProduct } from '../Firebase';
import { setLoader, setSelectProduct } from '../Redux/Authentication/Login';
import { Category, Product } from '../types';

const Products = () => {
  const [categoryList, setCategoryList] = useState(Array<Category>());
  const [productList, setProductList] = useState(Array<Product>());
  const productdata = Array<string>();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const updateData = () => {
    ReadCategory((data) => {
      setCategoryList(data);
    })
    ReadProduct((data) => {
      setProductList(data);
      dispatch(setLoader(false));
    })
  }

  const SelectData = (product: Product) => {
    dispatch(setSelectProduct(product.Id));
    navigate('/EditProduct');
  }

  const deleteProducts = (data: any) => {
    deleteProduct(data, () => {
      updateData()
    });
  }
  
  React.useEffect(() => {
    dispatch(setLoader(true));
    updateData()
  }, [])

  return (
    <div className="m-5">
      <div className="row tm-content-row">
        <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 tm-block-col">
          <div className="tm-bg-primary-dark tm-block tm-block-products">
            <div className="tm-product-table-container">
              <table className="table table-hover tm-table-small tm-product-table">
                <thead>
                  <tr>
                    <th scope="col" >&nbsp;</th>
                    <th scope="col">IMAGE</th>
                    <th scope="col">NAME</th>
                    <th scope="col">PRICE($)</th>
                    <th scope="col">STOCK</th>
                    <th scope="col">EXPIRE DATE</th>
                    <th scope="col">HIDE/SHOW</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    
                  </tr>
                </thead>
                <tbody>
                {
                    productList.map((data, index) => (
                      <tr key={index}>
                        <th scope="row"><input type="checkbox" onClick={() => {
                          productdata.push(data.Id);
                        }}/></th>
                        <td><img style={{borderRadius: '100%'}} height={40} width={40} src={data.Images ? data.Images[0] : ''}/></td>
                        <td className="tm-product-name">{data.Name}</td>
                        <td>{data.Current_price}</td>
                        <td>{data.Stock}</td>
                        <td>{data.Expire_date}</td>
                        <td onClick={() => {
                        
                      }}>
                          <label className="switch" style={{marginTop: 5}}>
                            <input type="checkbox" checked={data.Active} onChange={(e) => {
                              activeOrDeactiveProduct(data.Id, e.target.checked, () => {
                                updateData()
                              })
                            }}/>
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td onClick={() => {
                          deleteProducts(data.Id);
                        }}>
                          <a className="tm-product-delete-link">
                            <i className="far fa-trash-alt tm-product-delete-icon"></i>
                          </a>
                        </td>
                        <td onClick={() => {
                        SelectData(data);
                      }}>
                          <a className="tm-product-delete-link">
                            <i className="far fa-pen-alt tm-product-delete-icon"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  }
                  
                </tbody>
              </table>
            </div>
            
            <a
              href={"AddProduct"}
              className="btn btn-primary btn-block text-uppercase mb-3">Add new product</a>
            <button className="btn btn-primary btn-block text-uppercase" onClick={() => {
              deleteProducts(productdata);
            }}>
              Delete selected products
            </button>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 tm-block-col">
          <div className="tm-bg-primary-dark tm-block tm-block-product-categories">
            <h2 className="tm-block-title">Product Categories</h2>
            <div className="tm-product-table-container">
              <table className="table tm-table-small tm-product-table">
                <tbody>
                {
                    categoryList.map((data, index) => (
                      <tr key={index}>
                        <td className="tm-product-name">{data.Name}</td>
                        <td className="text-center" onClick={() => {
                          deleteCategory(data.Id, () => {
                            updateData();
                          })
                        }}>
                          <a href="#" className="tm-product-delete-link">
                            <i className="far fa-trash-alt tm-product-delete-icon"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            
            <button className="btn btn-primary btn-block text-uppercase mb-3" onClick={() => {
              navigate('/Category')
            }}>
              Add new category
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products