import React, { useRef } from 'react'
import * as ReactDOM from "react-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export const LinesChart = () => {
    const ref = useRef()
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    font: {
                        size: 14
                    },
                    color: '#fff'
                },  
            },
        },
        scales: {
            y: {
              ticks: {
                color: 'white'
              }
            },
            x: {
                ticks: {
                  color: 'white'
                }
              },
          }
    };
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const data = {
        labels,
        datasets: [
        {
            label: 'Order',
            data: [23, 45, 67,89,34,78,90],
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.3
        },
        {
            label: 'User',
            data: [90,76,43,45,78,28, 10],
            borderColor: 'rgb(53, 162, 235)',
            tension: 0.3
        },
        ],
    };

    React.useEffect(() => {
        console.log('kk',ref.current);
        
    }, [])

  return (
    <>
        <Line options={options} data={data} ref={ref}/>
    </>
  )
}

