import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { LoginFirebaseWineUser } from '../Firebase';
import { isLogin, user } from '../Redux/Authentication/Login';
import { User } from '../types';

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [userForm, setUserForm] = useState(({
    username: "",
    password: ""
  }))

  const handleChange = (e:any) => {
    setUserForm({
      ...userForm,
      [e.target.name]: e.target.value,
    })
  }


  return (
    <div className="container tm-mt-big tm-mb-big">
    <div className="row">
      <div className="col-12 mx-auto tm-login-col">
        <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="tm-block-title mb-4">Welcome to Dashboard, Login</h2>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <form className="tm-login-form" onSubmit={(data) => {
                data.preventDefault()
                console.log("logindata", userForm);
                LoginFirebaseWineUser(userForm.username, userForm.password).then((data) => {
                  console.log("loigin", data.email);
                  const userInfo = {
                    email: data.email,
                    uid: data.uid,
                    createTime: data.metadata.creationTime,
                    lastSigninTime: data.metadata.lastSignInTime
                }
                dispatch(user(userInfo as User))
                dispatch(isLogin(true))
                })
              }}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    name="username"
                    type="text"
                    className="form-control validate"
                    id="username"
                    placeholder="UserName"
                    onChange={handleChange}
                    value={userForm.username}
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor={"password"}>Password</label>
                  <input
                    name="password"
                    type="password"
                    placeholder="******"
                    className="form-control validate"
                    id="password"
                    onChange={handleChange}
                    value={userForm.password}
                    required
                  />
                </div>
                <div className="form-group mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase"
                  >
                    Login
                  </button>
                </div>
                <button className="mt-5 btn btn-primary btn-block text-uppercase">
                  Forgot your password?
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Login