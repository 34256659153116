import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product, User } from '../../types'

export const LoginSlice = createSlice({
    name: 'login',
    initialState: {
        user: JSON.parse(localStorage.getItem("user")?? JSON.stringify({} as User)),
        isLogin: localStorage.getItem("isLogin") ?? false,
        selectProduct: localStorage.getItem("selectProduct"),
        loader: false,
    },
    reducers: {
        user: (state, action: PayloadAction<User>) => {
            state.user = action.payload
            localStorage.setItem("user", JSON.stringify(action.payload))
        },
        isLogin: (state, action: PayloadAction<boolean>) => {
            state.isLogin = action.payload
            localStorage.setItem("isLogin", JSON.stringify(action.payload))
        },
        setSelectProduct: (state, action: PayloadAction<string>) => {
            state.selectProduct = action.payload
            localStorage.setItem("selectProduct", action.payload)
        },
        setLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload
        },
    }
})
export const  {user, isLogin, setSelectProduct, setLoader} = LoginSlice.actions
export default LoginSlice.reducer

