import React, { useState } from 'react';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter
} from "react-router-dom";
import Dashboard from './Components/Dashboard';
import Products from './Components/Products';
import Login from './Components/Login';
import AddProduct from './Components/AddProduct';
import EditProduct from './Components/EditProduct';
import Account from './Components/Account';
import { useSelector } from 'react-redux';
import { RootState } from './Redux/Store';
import { isUserLogin } from './Utils';
import AddCategory from './Components/AddCategory';
import OrderReport from './Components/OrderReport';
import Loader from './Components/Loader';
import AddPromoCode from './Components/AddPromoCode';
import Billing from './Components/Billing';
import BannerPage from './Components/Banner';



function App() {
  const login = useSelector((state: RootState) => state.login)
  const [isLogin, setLogin] = useState(isUserLogin(login.isLogin))
  React.useEffect(() => {
    setLogin(isUserLogin(login.isLogin))
  }, [login])
  

  const router = createBrowserRouter([
    {
      path: "/",
      element: isLogin ? <Dashboard/> : <Login/>,
    },
    {
      path: "/login",
      element: <Login/>,
    },
    {
      path: "/Dashboard",
      element: isLogin ? <Dashboard/> : <Login/>,
    },
    {
      path: "/Products",
      element: isLogin ? <Products/> : <Login/>,
    },
    {
      path: "/AddProduct",
      element: isLogin ? <AddProduct/> : <Login/>,
    },
    {
      path: "/EditProduct",
      element: isLogin ? <EditProduct/> : <Login/>,
    },
    {
      path: "/Account",
      element: isLogin ? <Account/> : <Login/>,
    },
    {
      path: "/Category",
      element: isLogin ? <AddCategory/> : <Login/>,
    },
    {
      path: "/Report",
      element: isLogin ? <OrderReport/> : <Login/>
    },
    {
      path: "/PromoCode",
      element: isLogin ? <AddPromoCode/> : <Login/>
    },
    {
      path: "/Banner",
      element: isLogin ? <BannerPage/> : <Login/>
    },
    {
      path: "/Billing",
      element: isLogin ? <Billing/> : <Login/>
    }
    
    
  ]);

  
  return (
    <>
    <Loader/>
      <BrowserRouter>
        <Navbar/>
      </BrowserRouter>
      <RouterProvider router={router} />
      <Footer/>
      
    </>
  );
}

export default App;
