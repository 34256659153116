import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isLogin, user } from '../Redux/Authentication/Login';
import { RootState } from '../Redux/Store';
import { User } from '../types';
import { isUserLogin } from '../Utils';

function Navbar() {
    const userinfo = useSelector((state: RootState) => state.login.user);
    const userLogin = useSelector((state: RootState) => state.login.isLogin);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    console.log('current Pathname 👉️', window.location.pathname);
    const pathname = window.location.pathname;

    React.useEffect(() => {
        console.log(userLogin);
        
        if (!userLogin) {
            navigate('/')
        }
    }, [userLogin])

  return (
    <nav className="navbar navbar-expand-xl">
        <div className="container h-100">
            <a className="navbar-brand" href="index.html">
                <h1 className="tm-site-title mb-0">Wine Admin</h1>
            </a>
            <button className="navbar-toggler ml-auto mr-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars tm-nav-icon"></i>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto h-100">
                    <li className="nav-item">
                        <a className={`nav-link ${pathname === "/" ? "active" : ""}`} href="/">
                            <i className="fas fa-tachometer-alt"></i>
                            Dashboard
                            <span className="sr-only">(current)</span>
                        </a>
                    </li>
                    <li className="nav-item">

                        <a className="nav-link dropdown-toggle" href="Report">
                            <i className="far fa-file-alt"></i>
                            <span>
                                Reports
                            </span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${pathname === "/products" ? "active" : ""}`} href={'products'} >
                            <i className="fas fa-shopping-cart"></i>
                            Products
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className={`nav-link ${pathname === "/account" ? "active" : ""}`} href="account">
                            <i className="far fa-user"></i>
                            Accounts
                        </a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-cog"></i>
                            <span>
                                Settings <i className="fas fa-angle-down"></i>
                            </span>
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="banner">Banner</a>
                            <a className="dropdown-item" href="Billing">Billing</a>
                            <a className="dropdown-item" href="PromoCode">PromoCode</a>
                        </div>
                    </li>
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        
                        {isUserLogin(userLogin) ? (<a className="nav-link d-block" style={{cursor: 'pointer'}} onClick={() => {
                            dispatch(user({} as User))
                            dispatch(isLogin(false))
                        }}>
                            {(userinfo as User).email} <b>,Logout</b><br/>
                            <label style={{color: 'orange'}}>Last Access:-</label> {(userinfo as User).lastSigninTime}
                        </a>) : null}
                        
                    </li>
                </ul>
            </div>
        </div>

    </nav>
  )
}

export default Navbar