import { useDispatch } from 'react-redux';
import { UserAccount, Category, Product, PromoCode, Review, Order, PaymentDetail, Banner } from './../types';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref as s_ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref, set, push, onValue, remove } from "firebase/database";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAzlQBhbCwbM9tj2iEUosPVIW5GWMPsOMg",
    authDomain: "dbmanage-fbd05.firebaseapp.com",
    databaseURL: "https://dbmanage-fbd05-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "dbmanage-fbd05",
    storageBucket: "dbmanage-fbd05.appspot.com",
    messagingSenderId: "462370498019",
    appId: "1:462370498019:web:8389a18d368dcd0dc5acd6",
    measurementId: "G-YKVRDFY9DZ"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const defaultStorage = getStorage(app);
const analytics = getAnalytics(app);


export const LoginFirebaseWineUser = async (email: string, pass: string) => {
    
    return signInWithEmailAndPassword(auth, email, pass).then(userCredential => {
        
        return userCredential.user
    }).catch(error => {
        return error
    }) 
}

export const CreateFirebaseWineUser = async (email: string, pass: string) => {
    // "wine.admin.com"
    return createUserWithEmailAndPassword(auth, email, pass).then(userCredential => {
        return userCredential.user
    }).catch(error => {
        // const errorcode = error.code;
        // const errorMessage = error.message
        return error.message;
    }) 
}

export const WriteCategory = async (categoryName: string) => {
    const addCategoryRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Category/${addCategoryRef.key}`);
    return set(newId, {Name: categoryName, Id: addCategoryRef.key})
}

export const WritePromoCode = async (Code: string, Percentage: number, Title: string) => {
    const newId = ref(database, `WineDatabase/PromoCode/${Code}`);
    return set(newId, {Code, Percentage, Title})
}

export const WriteImage = async (images: Array<any>, callback: (url: Array<String>) => unknown, UploadPercentag: (progress: number) => unknown) => {
    const imageUrls: Array<String> = []
    images.map((image) => {
        const storageRef = s_ref(defaultStorage, `Uploads/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress =
              Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              console.log('uplaouinsdf-----', progress);
              UploadPercentag(progress)
              
          },
          (error) => {
            alert(error);
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                imageUrls.push(downloadURL);
                if (imageUrls.length === images.length){
                    callback(imageUrls);
                }
                console.log('uplaouinsdf-----', imageUrls);
            });
          }
        );
    });

    
}

export const ImageUpload = async (image: any, callback: (url: string) => unknown, UploadPercentag: (progress: number) => unknown) => {
    const randomName = Math.floor((Math.random() * 45) + 1);
    const storageRef = s_ref(defaultStorage, `Uploads/${randomName}`);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          UploadPercentag(progress)
          
      },
      (error) => {
        alert(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            callback(downloadURL);
        });
      }
    );
}

export const RemoveImage = async (imageList: Array<string>, productid: string, callback: () => unknown) => {
    const productRef = ref(database, `WineDatabase/Products/${productid}/Images`);
    return set(productRef, imageList).then(() => callback());
}

export const ReadCategory = async (callback: (data: Array<Category>) => unknown) => {
    const CategoryList: Array<Category> = []
    const categoryRef = ref(database, 'WineDatabase/Category/');
    onValue(categoryRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            CategoryList.push(data as Category)
        })
        
        callback(CategoryList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const ReadProductReview = async (productId: string,callback: (data: Array<Review>) => unknown) => {
    const ReviewList: Array<Review> = []
    const ReviewRef = ref(database, `WineDatabase/Review/${productId}`);
    onValue(ReviewRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            ReviewList.push(data as Review)
        })
        
        callback(ReviewList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const DeleteProductReview = async (pId: string, id: string, callBack: () => unknown) => {
    const cId = ref(database, `WineDatabase/Review/${pId}/${id}`);
    remove(cId).then(() => {
        callBack();
    })
}



export const ReadPromoCode = async (callback: (data: Array<PromoCode>) => unknown) => {
    const PromoCodeList: Array<PromoCode> = []
    const PromoCodeRef = ref(database, 'WineDatabase/PromoCode/');
    onValue(PromoCodeRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            PromoCodeList.push(data as PromoCode)
        })
        
        callback(PromoCodeList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const UploadProduct = (product: any, file: Array<String>) => {
    
    const productObject = {Name: product.name, Images: file, Current_price: product.current_price, Original_price: product.original_price, Expire_date: product.expire_date, Category_id: product.category, Festival: product.festival, Stock: product.stock, Tax: 0, Description: product.description, Active: true}
    console.log(productObject);
    
    const addCategoryRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Products/${addCategoryRef.key}`);
    return set(newId, {...productObject, Id: addCategoryRef.key})
}

export const ReadProduct = async (callback: (data: Array<Product>) => unknown) => {
    const ProductList: Array<Product> = []
    const productRef = ref(database, 'WineDatabase/Products/');
    return onValue(productRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            
            ProductList.push(data as Product)
        })
        
        callback(ProductList)
        return ProductList
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const UpdateProduct = async (product: any, id: string, callback: (data: string) => unknown) => {
    const productObject = {Id: id, Name: product.Name, Images: product.Images, Current_price: product.Current_price, Original_price: product.Original_price, Expire_date: product.Expire_date, Category_id: product.Category_id, Festival: product.Festival, Stock: product.Stock, Tax: product.Tax, Description: product.Description, Active: product.Active};
    
    const productRef = ref(database, `WineDatabase/Products/${id}/`);
    set(productRef, productObject).then(() => {
        callback('Update Successfully')
    });
}

export const deleteProduct = async (id: string | Array<string>, callback: () => unknown) => {
    console.log('deleteproeuct', typeof id);
    if ( typeof id === 'string' ){
     const productRef = ref(database, `WineDatabase/Products/${id}/`);
     remove(productRef).then(() => {
        callback();
     });
    } else {
        // eslint-disable-next-line array-callback-return
        (id as Array<string>).map(id => {
            const productRef = ref(database, `WineDatabase/Products/${id}/`);
            remove(productRef).then(() => {
                callback();
            });
        })
     
    }
}

export const ProductDetail = async (id: string, callback: (detail: Product) => unknown) => {
    const productRef = ref(database, `WineDatabase/Products/${id}`);
    onValue(productRef, (snapshot) => {
        
        callback(snapshot.val() as Product)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const deleteCategory = async (id: string, callBack: () => unknown) => {
    const cId = ref(database, `WineDatabase/Category/${id}`);
    remove(cId).then(() => {
        callBack();
    })
}
export const deletePromoCode = async (id: string, callBack: () => unknown) => {
    const cId = ref(database, `WineDatabase/PromoCode/${id}`);
    remove(cId).then(() => {
        callBack();
    })
}


export const userAccounts = async (callback: (data: Array<UserAccount>) => unknown) => {
    const AccountList: Array<UserAccount> = []
    const productRef = ref(database, 'WineDatabase/Account/');
    onValue(productRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            AccountList.push(data as UserAccount)
        })
        
        callback(AccountList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })   
}

export const activeOrDeactiveUser = async (id: string, active: boolean, callback: () => unknown) => {
    const productRef = ref(database, `WineDatabase/Accounts/${id}/Active`);
    set(productRef, active).then(() => {
        callback();
    });
}

export const activeOrDeactiveProduct = async (id: string, active: boolean, callback: () => unknown) => {
    const productRef = ref(database, `WineDatabase/Products/${id}/Active`);
    set(productRef, active).then(() => {
        callback();
    });
}


export const getProductCount = async (callback: (count: number, cost: number) => unknown) => {
    let stockCount = 0;
    let cost= 0;
    await ReadProduct((product) => {
        product.map((data) => {
            stockCount += Number(data.Stock);
            cost += (Number(stockCount) * Number(data.Current_price));
        });
        callback(stockCount, cost);
    });
}

export const UserCount = async (callback: (count: number) => unknown) => {
    userAccounts((count) => callback(count.length))    
}

export const ReadOrder = async (callback: (data: Array<Order>) => unknown) => {
    const OrderList: Array<Order> = []
    const OrderRef = ref(database, `WineDatabase/Orders/`);
    onValue(OrderRef, (snapshot) => {
        snapshot.forEach((child) => {

            const OrderRef = ref(database, `WineDatabase/Orders/${child.key}`);
            onValue(OrderRef, (snapshot) => {
                snapshot.forEach((child) => {  
                    const data = child.val()
                    OrderList.push(data as Order)
                })
            }, (cancel) => {
                console.log('cancel',cancel.message);
            })             
        })
        
        callback(OrderList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const ProductStock = (callback: (data: Array<Object>) => unknown) => {
    let arrayLenghts: any = {};
    let CategoryProductCountList = Array<Object>();
    ReadProduct(product => {
        ReadCategory(data => {
            product.map(item => {
                arrayLenghts[item.Category_id] = arrayLenghts[item.Category_id] === undefined ? 1 : arrayLenghts[item.Category_id]+= 1;        
            });
            Object.keys(arrayLenghts).map(key => {
                CategoryProductCountList.push({name: data.find(item => item.Id === key)?.Name, count: arrayLenghts[key], color: Math.random().toString(16).substr(-6)})
            })
            callback(CategoryProductCountList);
            
        })
    })
   
    
    

    
    
    
    
    
} 

export const getBillingAndUserDetail = (id: string, callback: (data: any) => unknown) => {
    const Detail = Array<any>();
    const productRef = ref(database, `WineDatabase/PaymentData/${id}`);
    onValue(productRef, (snapshot) => {
        const payment = snapshot.val() as PaymentDetail
        Detail[0] = payment
        getUserAccountDetail(payment.customer_id, (user) => {
            Detail[1] = user
            callback(Detail)
        })
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })  
}

export const getUserAccountDetail = (id: string, callback: (data: UserAccount) => unknown) => {
    const productRef = ref(database, `WineDatabase/Account/${id}`);
    onValue(productRef, (snapshot) => {
        callback(snapshot.val() as UserAccount)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })  
}

export const AddBanners = (banner: string, label: string, callback: () => unknown) => {
    const bannerObject = {banner, label}
    
    const addbannerRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Banner/${addbannerRef.key}`);
    return set(newId, {...bannerObject, Id: addbannerRef.key}).then(() => callback())
}

export const ReadBanner = async (callback: (data: Array<Banner>) => unknown) => {
    const BannerList: Array<Banner> = []
    const BannerRef = ref(database, 'WineDatabase/Banner/');
    onValue(BannerRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            BannerList.push(data as Banner)
        })
        
        callback(BannerList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const ReadBillingData = async (callback: (data: Array<PaymentDetail>) => unknown) => {
    const PaymentDetailList: Array<PaymentDetail> = []
    const PaymentDetailRef = ref(database, 'WineDatabase/PaymentData/');
    onValue(PaymentDetailRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            PaymentDetailList.push(data as PaymentDetail)
        })
        
        callback(PaymentDetailList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const deleteBanners = async (id: string, callBack: () => unknown) => {
    const cId = ref(database, `WineDatabase/Banner/${id}`);
    remove(cId).then(() => {
        callBack();
    })
}
