import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Modal from 'react-responsive-modal';
import { useSearchParams } from "react-router-dom"
import { getBillingAndUserDetail, ReadOrder } from '../Firebase';
import { setLoader } from '../Redux/Authentication/Login';
import { Address, CartModel, Order, PaymentDetail, Product, UserAccount } from '../types';
import "react-responsive-modal/styles.css";

const OrderReport = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [order, selectOrder] = useState<Order>();
  const [user, setUser] = useState<UserAccount>();
  const [tax, setTax] = useState(0);
  const [billing, setBilling] = useState<PaymentDetail>();
  const [orderList, setorderList] = useState(Array<Order>());
  
  const getOrder = () => {
    ReadOrder(data => {
        console.log(data);
        setorderList(data);
        dispatch(setLoader(false));
    })
  }

  useEffect(() => {
    dispatch(setLoader(true));
    getOrder();
  }, [])

  const FindLocation = (address: any) => {
    let location = JSON.parse(address);
    return `${location['City']}/${location['Country']}`;
  }

  const getOrderDetail = (item: Order) => {
    selectOrder(item);
    getBillingAndUserDetail(item.BillingId, (detail) => {
      setBilling(detail[0]);
      setUser(detail[1]);
    })
    setOpen(true)
  }

  const makeAddress = (address: any) => {
    if(address === undefined){
      return '';
    }
     
    const location = JSON.parse(address) as Address
    return `${location.Address}, ${location.Area}, ${location.City}, ${location.Country}, ${location.PinCode}`;
  }

  const formatDate = (date: any) => {
    return moment(new Date(date).valueOf()).format('DD-MM-yyyy hh:mm')
  }

  

  const ProductData = (productDetail: any) => {
    if(productDetail === undefined){
      return Array<CartModel>();
    }
    const cartList = JSON.parse(productDetail) as Array<CartModel>
    let tax = 0;
    cartList.map(item => {
        tax += item.Price;
    })
    // {Number(order?.TotalPrice) - tax}
    // setTax(tax);
    return cartList
  }


  const countTax = (productDetail: any) => {
    if(productDetail === undefined){
      return 0;
    }
    const cartList = JSON.parse(productDetail) as Array<CartModel>
    let tax = 0;
    cartList.map(item => {
        tax += item.Price;
    })
    return tax
  }


  return (
    <div className='mt-5'>
    <div className="col-12 tm-block-col">
                <div className="tm-bg-primary-dark p-4 tm-block-h-auto tm-block-taller tm-block-scroll">
                    <h2 className="tm-block-title">Orders List</h2>
                    <select
                      className="custom-select tm-select-accounts col-2 float-right m-2"
                      name="category"
                    //   onChange={handleChange}
                      id="category"
                    >
                      <option selected>Select status</option>
                          <option>Moving</option>
                          <option>Pending</option>
                          <option>Cancelled</option>
                      
                    </select>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">NO.</th>
                                <th scope="col">ORDER NO.</th>
                                <th scope="col">STATUS</th>
                                <th scope="col">ORDER TIME</th>
                                <th scope="col">LOCATION</th>
                                <th scope="col">TOTAL PRICE</th>
                                <th scope="col">PHONE NUMBER</th>
                                <th scope="col">BILL NO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderList.map((item, index) => 
                                    <tr onClick={() => getOrderDetail(item)}>
                                        <th scope="row"><b>{index+1}</b></th>
                                        <th scope="row"><b>{item.id}</b></th>
                                        <td>
                                            <div className="tm-status-circle moving">
                                            </div>{item.Payment_Status}
                                        </td>
                                        <td><b>{moment(new Date(item.Timestamp).valueOf()).format('DD-MM-yyyy hh:mm')}</b></td>
                                        <td><b>{FindLocation(item.Address)}</b></td>
                                        <td><b>{item.TotalPrice}</b></td>
                                        <td>{item.PhoneNumber}</td>
                                        <td>{item.BillingId}</td>
                                    </tr>        
                                )
                            }
                            
                         
                        </tbody>
                    </table>
                   
                </div>
    </div>
    <Modal
      open={open}
      onClose={() => {}}
      center
      showCloseIcon={false}
      styles={{modal: {width: '40%'}}}
    >
      
      <section className="vh-0" style={{backgroundColor: '#35558a'}}>
        <div className="container py-5 w-100">
        <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header border-bottom-0">
                      <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close" onClick={() => setOpen(false)}></button>
                    </div>
                    <div className="modal-body text-start text-black p-4">
                    <h5 className="mb-1" style={{color: '#35558a'}}>OrderDetail</h5>
                      <h6 className="modal-title text-uppercase mb-1" id="exampleModalLabel">{user?.Name}</h6>
                      <span className='small'>Address: {makeAddress(order?.Address)}</span>
                      <div className="d-flex justify-content-between pb-1">
                        <p className="mb-0" style={{color: '#35558a'}}>Payment summary</p>
                        <div>
                          <p className="mb-0 small" style={{color: '#35558a', textAlign:'right'}}>Status:- {order?.Payment_Status}</p>
                          <p className="mb-0 small" style={{color: '#35558a'}}>Date:- {formatDate(order?.Timestamp)}</p>
                        </div>
                      </div>
                      <hr className="mt-2 mb-4" style={{height: 0, backgroundColor: 'transparent', opacity: .75, borderTop: '2px dashed #9e9e9e'}}/>

                      

                      {
                        ProductData(order?.Product).map(data => 
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{data.Product?.Name}(Qty:{data.Quantity})</p>
                            <p className="text-muted mb-0">${data.Price}.00</p>
                          </div>    
                        )
                      }
                      {/* <div className="d-flex justify-content-between">
                        <p className="small mb-0">Shipping</p>
                        <p className="small mb-0">$00.00</p>
                       </div>     */}
                      

                      <div className="d-flex justify-content-between pb-1">
                        <p className="small">Tax</p>
                        <p className="small">${Number(order?.TotalPrice) - countTax(order?.Product)}.00</p>
                      </div>

                      <div className="d-flex justify-content-between">
                        <p className="fw-bold">Total</p>
                        <p className="fw-bold" style={{color: '#35558a'}}>${order?.TotalPrice}.00</p>
                      </div>

                    </div>
                    <div className="modal-footer d-flex justify-content-center border-top-0 py-4">
                      <button type="button" className="btn btn-primary btn-lg mb-1" style={{backgroundColor: '#35558a'}}>
                        Track your order
                      </button>
                    </div>
                  </div>
                </div>
        </div>
      </section>

     
    </Modal>
    </div>

  )
}

export default OrderReport