import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Form } from "react-router-dom"
import { deleteCategory, ReadCategory, WriteCategory } from '../Firebase'
import { setLoader } from '../Redux/Authentication/Login';
import { Category } from '../types';

const AddCategory = () => {
  const [categoryList, setCategoryList] = useState(Array<Category>());
  const [product, setProduct] = useState(({
    name: "",
  }))
  const dispatch = useDispatch()

  const handleChange = (e: any) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }
  
  const updateData = () => {
    ReadCategory((data) => {
      setCategoryList(data)
      dispatch(setLoader(false));
    })
  }

  
 React.useEffect(() => {
  dispatch(setLoader(true));
  updateData()
 }, [])

  return (
    <div className="container tm-mt-big tm-mb-big">
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-8 mx-auto">
          <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
            <div className="row">
              <div className="col-12">
                <h2 className="tm-block-title d-inline-block">Add Category</h2>
              </div>
            </div>
            <Form method='post' className="tm-edit-product-form" onSubmit={(e) => { 
                e.preventDefault()
                console.log("Product-insert", product);
                WriteCategory(product.name).then((data) => {
                  console.log("CategoryAdded", data);
                  updateData()
                })
            }}>
            <div className="tm-edit-product-row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                
                  <div className="form-group mb-3">
                    <label
                      htmlFor="name"
                      >Category Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder='Category Name'
                      value={product.name}
                      onChange={handleChange}
                      className="form-control validate"
                      required
                    />
                  </div>
              
                  
              </div>
       
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block text-uppercase">Add Category Now</button>
              </div>
            </div>
            </Form>
          </div>
          
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 tm-block-col">
          <div className="tm-bg-primary-dark tm-block tm-block-product-categories">
            <h2 className="tm-block-title">Product Categories</h2>
            <div className="tm-product-table-container">
              <table className="table tm-table-small tm-product-table">
                <tbody>
                  {
                    categoryList.map((data, index) => (
                      <tr key={index}>
                        <td className="tm-product-name">{data.Name}</td>
                        <td className="text-center" onClick={() => {
                          dispatch(setLoader(true));
                          deleteCategory(data.Id, () => {
                            updateData();
                          })
                        }}>
                          <a href="#" className="tm-product-delete-link">
                            <i className="far fa-trash-alt tm-product-delete-icon"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div> 
    </div>
  )
}

export default AddCategory